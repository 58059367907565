import { graphql } from 'gatsby';
import React from 'react';
import BlogImage from '../components/BlogImage';
import FullWidthBanner from '../components/FullWidthBanner';
import FullWidthContent from '../components/FullWidthContent';
import Gallery from '../components/Gallery';
import HeroSection from '../components/HeroSection';
import ImageContent from '../components/ImageContent';
import RelatedCards from '../components/RelatedCards';
import ShareBlog from '../components/ShareBlog';

const BlogModules = (props) => {
  const modules = props.modules.blogContent;
  const { location } = props;
  const components = {
    FullWidthBanner,
    Gallery,
    ImageContent,
    HeroSection,
    FullWidthContent,
    BlogImage,
    ShareBlog,
    RelatedCards,
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpPost_Blogcontent_BlogContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                i,
                postData: props.postData,
              })
            );
          })
        : null}
    </>
  );
};

export default BlogModules;

export const BlogModulesFragment = graphql`
  fragment BlogModulesFragment on WpPost {
    blogContent {
      blogContent {
        __typename
        ...BlogFullWidthBannerFragment
        ...GalleryBlogFragment
        ...BlogImageContentFragment
        ...BlogHeroSection
        ...BlogFullWidthContent
        ...BlogImageFragment
        ...RelatedCardsBlogFragment
      }
      blogBannerImage {
        mediaItemUrl
        altText
      }
    }
  }
`;
